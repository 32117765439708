
import { Slider, InfiniteScroll } from 'antd-mobile';
import { useEffect, useState, useReducer, useRef } from 'react';
import './index.scss'
import { VideoList } from './component/video-list';
import { http } from '../../utils';
import { useDocumentTtitle, useRouteId } from '../../utils/common';
import { useVideoDetail } from '../../utils/video';
import { sleep } from 'antd-mobile/es/utils/sleep';
import { useHttp } from './../../utils/index';

const _padl = (t) => {
  let m = parseInt(t / 60)
  let mm = m < 10 ? '0' + m : m
  let s = parseInt(t % 60)
  let ss = s < 10 ? '0' + s : s
  return `${mm}:${ss}`
}

export const Video = () => {
  useDocumentTtitle('视频详情')
  const videoRef = useRef()
  const [hasMore, setHasMore] = useState(true)
  //h5自适应屏幕 
  let clientWidth = window.innerWidth || document.documentElement.getBoundingClientRect().width || document.documentElement.clientWidth
  useEffect(() => {
    const resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize'
    document.documentElement.style.fontSize = 1 * (clientWidth / 750) + 'px'
    window.addEventListener(resizeEvt, () => {
      document.documentElement.style.fontSize = 1 * (clientWidth / 750) + 'px'
    })
  }, [clientWidth])

  const params = useRouteId() //获取路由参数
  const [isPlay, setIsPlay] = useState(false)//是否播放过
  const [play, setPlay] = useState(false)//播放
  const [videoList, setVideoList] = useState([]) //视频列表
  const [isLoading, setIsLoading] = useState(true)
  const [state, dispatch] = useReducer((state, action) => ({ ...state, ...action }), {
    isShowVideo: true,//是否显示播放器
    duration: 0, //视频时长
    leftTime: 0, //播放时显示时间
    sliderValue: 0, //滑动条的值
    profile: {}, //用户信息
    video: {}, //视频详情
    movieFile: params.url, //视频地址
    page: 1,
    pageSize: 20,
    totalPages: 1
  })
  const { token, url, memberId } = params
  const { duration, leftTime, sliderValue, profile, video, movieFile, page, pageSize, totalPages, isShowVideo } = state
  const { data: videoData, retry } = useVideoDetail(params)
  const client = useHttp(token)

  //更新播放量
  const onChangeHit = (myno,type) => {
    http(`golfonlineWeapplet/rest/video/hit`, {
      method: 'PUT',
      token,
      data:{
        myno,
        type
      }
    }).then(res => {
      if (res.code === 200) {
        setIsPlay(true)
        const temVideoList = [...videoList]
        temVideoList.forEach(item => {
          if (item.myno === myno) {
            item.hit = Number(item.hit) + 1
          }
        })
        setVideoList(temVideoList)
      }
    })
  }

  //点赞
  const onChangeLike = (myno, isLike,type) => {
    http(`golfonlineWeapplet/rest/video/recomm`, {
      method: 'PUT',
      token,
      data:{
        type,
        myno
      }
    }).then(res => {
      if (res.code === 200) {
        if (!isNaN(Number(video?.recomm))) {
          const count = video?.recomm
          if (isLike) {
            video.recomm = Number(count) - 1
            video.isLike = false
          } else {
            video.recomm = Number(count) + 1
            video.isLike = true
          }
          dispatch({
            video
          })
        }
        const temVideoList = [...videoList]
        temVideoList.forEach(item => {
          if (item.myno === myno) {
            item.recomm = isLike ? Number(item.recomm) - 1 : Number(item.recomm) + 1
            item.isLike = !isLike
          }
        })
        setVideoList(temVideoList)
      }
    })
  }

  //初始化视频详情
  useEffect(() => {
    dispatch({
      profile: videoData?.data.profile,
      video: videoData?.data.video,
      movieFile: url,
      isShowVideo: videoData?.data.flag
    })
    videoRef.current?.play().then(() => videoRef.current.pause())
  }, [videoData, url])

  //播放视频
  const onVideoPlay = (myno,type) => {
    console.log(myno)
    const playSatus = play
    setPlay(!play)
    if (!playSatus) {
      videoRef.current.play()
      if (!isPlay) {
        onChangeHit(myno,type)
      }
    } else {
      videoRef.current.pause()
      dispatch({
        leftTime: videoRef.current.currentTime
      })
    }
  }
  //获取视频列表
  const getVideoList = async () => {
    return new Promise(resolve => {
      client('golfonlineWeapplet/rest/video/mine', {
        data: {
          memberId,
          page,
          pageSize: pageSize || 20
        },
        token
      }).then(res => {
        if (res.code === 200) {
          dispatch({
            totalPages: res.totalPages,
            page: page + 1
          })
          resolve(res.rows)
        }
      })
    })
  }

  const mockRequest = async () => {
    if (page > totalPages) {
      return []
    }
    await sleep(500)
    return await getVideoList()
  }
  //上拉加载更多
  async function loadMore() {
    const append = await mockRequest()
    setVideoList(val => [...val, ...append])
    // console.log(videoList)
    setHasMore(append.length > 0)
  }

  return <div className='video_page'>
    {
      isShowVideo ? <div className='video_box'>
        <video
          ref={videoRef}
          preload="auto"
          poster={video?.imgFile}
          webkit-playsinline="true"
          playsInline
          x5-video-player-type="h5"
          onCanPlay={
            () => {
              dispatch({
                duration: videoRef.current.duration ? videoRef.current.duration : 0
              })
            }
          }
          onLoadedMetadata={() => {
            dispatch({
              duration: videoRef.current.duration ? videoRef.current.duration : 0
            })
          }}
          onTimeUpdate={() => {
            dispatch({
              leftTime: videoRef.current.currentTime,
              sliderValue: videoRef.current.currentTime
            })
          }}
          onEnded={() => {
            dispatch({
              leftTime: videoRef.current.currentTime
            })
            setPlay(false)
          }}>
          <source src={movieFile} type="video/mp4" id="source" />
        </video>

        {
          isLoading ? <div className={'loading_box flex_center'} onClick={() => {
            setIsLoading(false)
            onVideoPlay(video?.myno,video?.type)
          }}>
            <img className='play_img' src={require('../../assets/imgs/video_playing.png')} alt="" />
          </div> : null
        }
        <div className={!isLoading ? 'is_playing control_box flex_btw' : 'control_box flex_btw'}>
          <div className="click_area flex" onClick={(e) => {
            e.stopPropagation()
            onVideoPlay(video?.myno,video?.type)
          }}>
            <img className="control_icon" src={!play ? require('../../assets/imgs/video_play.png') : require('../../assets/imgs/video_pause.png')} alt="" />
          </div>
          <div className="flex">
            <div className="play_time">{_padl(leftTime)}</div>
            <Slider step={0.01} value={sliderValue} onChange={(value) => {
              dispatch({
                sliderValue: value
              })
              videoRef.current.currentTime = value
            }} className='mySlider' min={0} max={duration}></Slider>
            <div className="play_time">{_padl(duration) || '00:00'}</div>
          </div>
          <div className="flex ml-20" onClick={() => onChangeLike(video?.myno, video?.isLike,video?.type)}>
            <img className="icon_img mr-5" src={video?.isLike ? require('../../assets/imgs/like.png') : require('../../assets/imgs/unlike.png')} alt="" />
            <div className="play_time">{video?.recomm}</div>
          </div>
        </div>
      </div> : null
    }
    <div className="video_contanier">
      <div className="person_info flex_btw container">
        <div className='flex'>
          <img className='person_avartar' src={profile?.avatarUrl ? profile?.avatarUrl : '../../assets/imgs/message_avartar.png'} alt="" />
          <div className="ml-10 person_name">{profile?.nickName}</div>
        </div>
        <div className='myregdate'>{video?.myregdate}</div>
      </div>
    </div>
    <div className="video_list_container">
      <div className="video_title">视频列表</div>
      <VideoList videoDetailRetry={retry} videoList={videoList} setVideoList={setVideoList} />
    </div>
    <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
  </div>
}