import qs from 'qs'
import { useCallback } from 'react';

export const apiUrl = process.env.REACT_APP_API_URL

export const http = (endpoint, { data, headers, token, ...customConfig }) => {

  let configHeader = {
    'Content-Type': 'application/json',
  }
  if (token) {
    configHeader = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }
  const config = {
    method: 'GET',
    headers: configHeader,
    ...customConfig
  }

  if (config.method.toUpperCase() === 'GET') {
    endpoint = data ? `${endpoint}?${qs.stringify(data)}` : endpoint
  } else {
    config.body = JSON.stringify(data)
  }

  return window.fetch(`${apiUrl}${endpoint}`, config).then(async response => {
    if (response.status === 404) {
      return Promise.reject({ msg: '你访问的资源不存在' })
    }
    const res = await response.json()
    if (response.ok) {
      if (res.code === 200) {
        return res
      } else {
        return Promise.reject(res)
      }
    } else {
      return Promise.reject(res)
    }
  })
}

//获取context中的user的token
export const useHttp = (token) => {
  return useCallback((endpoint, config) => http(endpoint, { ...config, token }), [token])
}