// import { useEffect, useReducer } from 'react';
import { http } from '../../../utils';
import { useRouteId } from './../../../utils/common';
import { useNavigate } from 'react-router-dom';

export const VideoList = ({ videoDetailRetry, videoList, setVideoList }) => {
  const params = useRouteId()
  const navigate = useNavigate()
  const { token } = params

  //点赞
  const onChangeLike = (myno, isLike,type) => {
    http(`golfonlineWeapplet/rest/video/recomm`, {
      method: 'PUT',
      token,
      data:{
        type,
        myno
      }
    }).then(res => {
      if (res.code === 200) {
        const videoLists = [...videoList]
        videoLists.forEach(item => {
          if (item.myno === myno) {
            item.isLike = !isLike
            if (!isNaN(Number(item.recomm))) {
              if (isLike) {
                item.recomm = Number(item.recomm) - 1
              } else {
                item.recomm = Number(item.recomm) + 1
              }
            }
          }
        })
        setVideoList(videoLists)
        videoDetailRetry()
      }
    })
  }

  return <div className="video_list flex_btw mt-30">
    {
      videoList?.map((item, index) => <div className='mb-20' key={index}>
        <div className="video_item" onClick={() => {
          navigate(`/video?myno=${item.myno}&memberId=${item.memberId}&url=${item.movieFile}&token=${token}`)
          window.location.reload()
        }}>
          <img className="video_img" src={item.imgFile} alt="" />
          <div className="bottom_tip flex">
            <div className="flex mr-20">
              <img className="icon_img" src={require('../../../assets/imgs/play.png')} alt='' />
              <div className='text'>{item.hit}</div>
            </div>
            <div className="flex" onClick={(e) => {
              e.stopPropagation()
              onChangeLike(item.myno, item.isLike,item.type)
            }}>
              <img className="icon_img" src={item.isLike ? require('../../../assets/imgs/like.png') : require('../../../assets/imgs/unlike.png')} alt='' />
              <div className='text'>{item.recomm}</div>
            </div>
          </div>
          {
            item?.qjYn ? <view class="overall">全景</view> : null
          }
        </div>
        <div className='myregdate'>{item.myregdate}</div>
      </div>
      )
    }
  </div>
}