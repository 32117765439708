import './index.scss'
import { useEffect } from 'react';
import { useDocumentTtitle } from '../../utils/common';

export const ScanCode = () => {
  useDocumentTtitle('服务员端')
  let clientWidth = window.innerWidth || document.documentElement.getBoundingClientRect().width || document.documentElement.clientWidth
  useEffect(() => {
    const resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize'
    if (clientWidth > 500) {
      document.documentElement.style.fontSize = 1 * (clientWidth / 1624) + 'px'
    } else {
      document.documentElement.style.fontSize = 1 * (clientWidth / 750) + 'px'
    }
    window.addEventListener(resizeEvt, () => {

      if (clientWidth > 500) {
        document.documentElement.style.fontSize = 1 * (clientWidth / 1624) + 'px'
      } else {
        document.documentElement.style.fontSize = 1 * (clientWidth / 750) + 'px'
      }
    })
  }, [clientWidth])

  return (<div className="scan_code_container">
    <img className='scan_code_img' src={require('../../assets/imgs/scan_code.png')}></img>
    <div className='scan_text'>正在开发中，敬请期待～</div>
  </div>)
}