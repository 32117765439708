
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useRef } from 'react';

export const useRouteId = () => {
  const units = useLocation().search.split('?')[1].split('&')
  const obj = {}
  units.forEach(item=>{
    const arr = item.split('=')
    obj[arr[0]] = arr[1]
  })
  // window.localStorage.setItem('access_token',obj.token)
  return obj
}

//文档标题
export const useDocumentTtitle = (title, keepOnUnmount = true) => {
  const oldTitle = useRef(document.title).current
  useEffect(() => {
    document.title = title
  }, [title])
  useEffect(() => {
    return () => {
      if (!keepOnUnmount) {
        document.title = oldTitle
      }
    }
  }, [keepOnUnmount, oldTitle])
}