import './index.scss'
import { useEffect, useState, useReducer, useMemo } from 'react';
import html2canvas from 'html2canvas'
import { http } from '../../utils';
import { SpinLoading, Toast } from 'antd-mobile'

export const ImageIndex = ({ imgShare, setShare }) => {
  const [img, setImg] = useState()
  const [shareData, setShareData] = useState()
  const courseList = shareData?.courseList
  const playerList = shareData?.playerList
  const search = window.location.search?.split('?')[1]
  const paramsList = useMemo(() => search?.split('&'), [search])
  const [state, dispatch] = useReducer((state, action) => ({ ...state, ...action }), {
    devId: '',
    gameMark: '',
    roomId: '',
    sign: ''
  })
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const obj = {}
    paramsList?.forEach(params => {
      const param = params.split('=')
      obj[param[0]] = param[1]
    })
    dispatch({
      ...obj
    })
  }, [paramsList])
  //获取战绩数据
  useEffect(() => {
    const { devId } = state
    if (devId) {
      http('/golfonlineClub/clubPlayOrder/findShareData', {
        data: state
      }).then(res => {
        if (res.code === 200) {
          setShareData(res.data)
          setLoading(false)
          setTimeout(() => {
            let htmlDom = document.querySelector('#sharePicBox')
            if (htmlDom) {
              html2canvas(htmlDom, {
                allowTaint: false, //允许污染

                taintTest: true, //在渲染前测试图片(没整明白有啥用)

                useCORS: true, //使用跨域(当allowTaint为true时这段代码没什么用,下面解释)

                backgroundColor: null,

              }).then(function (canvas) {
                let baseImg = canvas.toDataURL('image/jpeg', 1.0)
                // console.log(baseImg)
                setImg(baseImg)
                setShare(true)
              })
            }
          }, 1000)
        }
      }).catch(error => {
        setLoading(false)
        Toast.show({
          content: error.msg,
        })
      })
    }
  }, [state, setShare, imgShare])

  return (<div className='index_container'>
    <div className={'tip_mode' + (imgShare ? ' show' : '')}>
      <img src={require('../../assets/imgs/tips.png')} alt="" />
      <div>长按保存图片</div>
    </div>
    <div className='loding_icon flex-center'>
      {
        loading ? <SpinLoading color='primary' /> : null
      }
    </div>

    {
      !imgShare ? <div id='sharePicBox' className="image_index_container">
        <div className="flex title">
          <div>{shareData?.courseName}</div>
          {
            shareData?.gameType ? <div className="circle"></div> : null
          }
          <div>{shareData?.gameType}</div>
        </div>
        <div className='score_list'>
          {
            courseList?.map((course, index) => {
              return (
                <div className={'rows flex' + (index === 1 ? ' red_rows' : index === 3 ? ' blue_rows' : '')} key={index}>
                  {
                    course?.map((item, itemIndex) => <div
                      className={'row_item' +
                        (itemIndex === 0 ? ' flex' : ' flex-center') +
                        (itemIndex === 10 || itemIndex === 20 || itemIndex === 21 ? ' total_out' : '')}
                      key={itemIndex}
                    >
                      {item}
                    </div>)
                  }
                </div>
              )
            })
          }
        </div>
        <div className='score_list user_score_list'>
          {
            playerList?.map((player, index) => {
              return (
                <div className='user_score_rows flex' key={index}>
                  {
                    player?.map((playerScore, playerIndex) => {
                      return (
                        <div className={'row_item' +
                          (playerIndex === 0 ? ' flex' : ' flex-center') +
                          (playerIndex === 10 || playerIndex === 20 || playerIndex === 21 ? ' total_out' : '')}
                          style={{ backgroundColor: playerScore?.color }}
                          key={playerIndex}>
                          {
                            playerIndex === 0 ? <>
                              <img className='avartar' src={playerScore?.memberNickImg} alt="" />
                              <p className='username'>{playerScore?.memberNick.slice(0, 5) + (playerScore?.memberNick.length > 5 ? '...' : '')}</p>
                            </> : playerScore?.hitCnt || ''
                          }
                        </div>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </div>
      </div> : <img className='share_img' src={img} alt="" />
    }
  </div>)
}