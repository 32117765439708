
import { ImageIndex } from './image-index';
import { useEffect, useState } from 'react';

export const Index = ()=>{
  const [imgShare, setShare] = useState(false)
  let clientWidth = window.innerWidth || document.documentElement.getBoundingClientRect().width || document.documentElement.clientWidth
  useEffect(() => {
    const resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize'
    if (clientWidth > 500) {
      document.documentElement.style.fontSize = 1 * (clientWidth / 1624) + 'px'
    } else {
      document.documentElement.style.fontSize = 1 * (clientWidth / 750) + 'px'
    }
    window.addEventListener(resizeEvt, () => {
      setShare(false)
      if (clientWidth > 500) {
        document.documentElement.style.fontSize = 1 * (clientWidth / 1624) + 'px'
      } else {
        document.documentElement.style.fontSize = 1 * (clientWidth / 750) + 'px'
      }
    })
  }, [clientWidth])

  return (
    <div className='image_container'>
      <ImageIndex imgShare={imgShare} setShare={setShare} />
    </div>
  );
}