
import { useHttp } from './index';
import { useAsync } from './use-async';
import { useCallback, useEffect } from 'react';

//查询视频详情
export const useVideoDetail = (params) => {
  const { memberId, myno, token } = params
  const client = useHttp(token)
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineWeapplet/rest/video/detail`, {
    data: {
      memberId,
      myno
    }
  }), [client, memberId, myno])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//查询视频列表
export const useVideoList = (params) => {
  const { token, memberId, page, pageSize } = params
  const client = useHttp(token)
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineWeapplet/rest/video/mine`, {
    data: {
      memberId,
      page,
      pageSize:pageSize || 20
    }
  }), [client, memberId, page, pageSize])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}