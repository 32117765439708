
import './App.scss';
import { Index } from './screen/index/index';
import { Routes, Route } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'
import { Video } from './screen/video';
import { ScanCode } from './screen/scan-code';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path={'/'} element={<Index />} />
          <Route path={'/video'} element={<Video />} />
          <Route path={'/scan_code'} element={<ScanCode />} />
        </Routes>
      </Router>

    </div>
  );
}

export default App;
